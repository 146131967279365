
@-webkit-keyframes videoBtnAnim {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    75% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@keyframes videoBtnAnim {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    75% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@keyframes videoBtnAnim6 {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }

    25% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }

    50% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    75% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }

    to {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }
}

@keyframes videoBtnAnimSix2 {
    0% {
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
    }

    25% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    50% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }

    75% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    to {
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
    }
} 

@keyframes flip {
    0% {
        transform: rotateX(0);
    }

    100% {
        transform: rotateX(360deg);
    }
}


@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }
}

@keyframes shake-that {
    0% {
        transform: translate(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translate(0);
    }
}